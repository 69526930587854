import { CSVLink, CSVDownload } from "react-csv";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
  ];

export default function ExportSuper(){

    const [notificationModalVisible, setNFVisible] = useState(false);
    const [uploadingVisible, setUploadingVisibility] = useState(false);
    const [successVisible, setSuccessVisibility] = useState(false);
    const [downloadingVisible, setDownloadingVisibility] = useState(false);
    const [csvData, setCSVData] = useState([]);
    const [downloadVisible, setDownloadVisibility] = useState(false);

    const [errorVisible, setErrorVisibility] = useState(false);
    const [errorList, setErrorList] = useState([]);

    useEffect(() => {
        if(window.sessionStorage.getItem("authorizationLevel") !== "SUPERUSER"){
            alert("Sorry You Can't View This Page");
            document.location.href = "/login";
        }
    });

    const closeModal = () => {
        setNFVisible(false);
        setSuccessVisibility(false);
        setErrorVisibility(false);
        setDownloadVisibility(false);
    };
    const [startMonth, setStartMonth] = useState("");
    const [startYear, setStartYear] = useState("");
    const [endMonth, setEndMonth] = useState("");
    const [endYear, setEndYear] = useState("");
    const [house, setHouse] = useState("");


    function buildQueryString({ startYear, startMonth, endYear, endMonth, house }) {
        const params = [
            startYear && `startYear=${startYear}`,
            startMonth && `startMonth=${startMonth}`,
            endYear && `endYear=${endYear}`,
            endMonth && `endMonth=${endMonth}`,
            house && `houseId=${house}`
        ].filter(Boolean); // Remove any falsy values (e.g., undefined or "")
    
        return params.length > 0 ? `?${params.join('&')}` : '';
    }

    const handleSubmit = async () => {
        try{
            setDownloadingVisibility(true);
            console.log(`https://flutr.org:8282/api/reports/export${buildQueryString({startYear: startYear, startMonth: startMonth, endYear: endYear, endMonth: endMonth, house: house})}`);
            const response = await fetch(`https://flutr.org:8282/api/reports/export${buildQueryString({startYear: startYear, startMonth: startMonth, endYear: endYear, endMonth: endMonth, house: house})}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem("accessKey")
                }
            });
            response.json().then(json => {
                setDownloadingVisibility(false);
                console.log(json);
                if(json.success){
                    setCSVData(json.payload);
                    setDownloadVisibility(true);
                }

            })
        }
        catch (error) {
            console.log('Failed to fetch', error);
        }
        
    }
    



const DateRangePicker = ({handleSubmit, startMonth, setStartMonth, startYear, setStartYear, endMonth, setEndMonth, endYear, setEndYear, house, setHouse}) => {
    const months = [
        { value: "", label: "Select Month" },
        { value: "1", label: "January" },
        { value: "2", label: "February" },
        { value: "3", label: "March" },
        { value: "4", label: "April" },
        { value: "5", label: "May" },
        { value: "6", label: "June" },
        { value: "7", label: "July" },
        { value: "8", label: "August" },
        { value: "9", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" },
    ];

    const years = Array.from({ length: 101 }, (_, index) => {
        const year = new Date().getFullYear() - index;
        return { value: year, label: year };
    });
    
    const locations = JSON.parse(window.sessionStorage.getItem('locations'));

    return (
        
        <div>
            <div>
                <label>Start Date:</label>
                <select value={startMonth} onChange={(e) => setStartMonth(e.target.value)}>
                    {months.map((month) => (
                        <option key={month.value} value={month.value}>
                            {month.label}
                        </option>
                    ))}
                </select>
                <select value={startYear} onChange={(e) => setStartYear(e.target.value)}>
                    <option value="">Select Year</option>
                    {years.map((year) => (
                        <option key={year.value} value={year.value}>
                            {year.label}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>End Date (Optional):</label>
                <select value={endMonth} onChange={(e) => setEndMonth(e.target.value)}>
                    {months.map((month) => (
                        <option key={month.value} value={month.value}>
                            {month.label}
                        </option>
                    ))}
                </select>
                <select value={endYear} onChange={(e) => setEndYear(e.target.value)}>
                    <option value="">Select Year</option>
                    {years.map((year) => (
                        <option key={year.value} value={year.value}>
                            {year.label}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>Locations:</label>
                <select value={house} onChange={(e) => setHouse(e.target.value)}>
                    <options key="" value="">Select Location</options>
                    {locations.map((location) => (
                        <option key={location.houseId} value={location.houseId}>
                            {location.name}
                        </option>
                    ))}
                </select>
            </div>
            <button onClick={handleSubmit}>Submit</button>
        </div>
    );
};

    const DownloadingModal = ({isVisible}) => {
        if(!isVisible) return null;

        return (
            <div className='notification-modal'>
                <div className='modal-content'>
                    <h2>Getting Data...</h2>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <p>This Could Take Some Time.</p>
                </div>
            </div>
        );
    }

    const DownloadModal = ({isVisible, csvData, onClose}) => {
        if(!isVisible) return null;

        return (
            <div className='notification-modal'>
                <div className='modal-content'>
                    <h2>Export Complete</h2>
                    <CSVLink data={csvData} filename={`${window.sessionStorage.getItem("houseID")}'s Report.csv`}>Click To Download</CSVLink>
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        );
    }


    const UploadingModal = ({isVisible}) => {
        if(!isVisible) return null;

        return (
            <div className='notification-modal'>
                <div className='modal-content'>
                    <h2>Importing...</h2>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <p>This Could Take Some Time.</p>
                </div>
            </div>
        );
    }

    const NotificationModal = ({ isVisible, onClose }) => {
        if (!isVisible) return null;
    
        return (
            <div className='notification-modal'>
                <div className='modal-content'>
                    <h2>Successfully imported!</h2>
                    <p>File processed successfully.</p>
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        );
    };

    const ErrorModal = ({ isVisible, onClose, errorList = [] }) => {
        if (!isVisible) return null;
    
        // Limit to the first 10 errors
        const displayedErrors = errorList.slice(0, 10);
        const excessiveErrors = errorList.length > 10;
    
        return (
            <div className='notification-modal'>
                <div className='modal-content'>
                    <h2>Error Importing File</h2>
                    <p>{`Number of errors: ${errorList.length}`}</p>
                    
                    {displayedErrors.length > 0 && (
                        <ul>
                            {displayedErrors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    )}
                    
                    {excessiveErrors && (
                        <p>
                            There are too many errors to display. Please check your file for significant issues.
                        </p>
                    )}
                    
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        );
    };

    const SuccessModal = ({ isVisible, onClose }) => {
        if (!isVisible) return null;
    
        return (
            <div className='notification-modal'>
                <div className='modal-content'>
                    <h2>Successfully imported!</h2>
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        );
    };

    
    return(
        <div >
            <Navbar/>
            <div style={{width: '75%', margin: 'auto', textAlign: 'center'}}>
                <div>
                    <div>
                        <h1>Export Data</h1>
                        <DateRangePicker handleSubmit={handleSubmit} startMonth={startMonth} setStartMonth={setStartMonth} startYear={startYear} setStartYear={setStartYear} endMonth={endMonth} setEndMonth={setEndMonth} endYear={endYear} setEndYear={setEndYear} house={house} setHouse={setHouse}/>
                    </div>
                </div>
            </div>
            <Footer/>
            <NotificationModal onClose={closeModal} isVisible={notificationModalVisible}/>
            <UploadingModal isVisible={uploadingVisible}/>
            <SuccessModal isVisible={successVisible} onClose={closeModal}/>
            <ErrorModal isVisible={errorVisible} onClose={closeModal} errorList={errorList}/>
            <DownloadingModal isVisible={downloadingVisible}/>
            <DownloadModal isVisible={downloadVisible} csvData={csvData} onClose={closeModal}/>
        </div>
    )
}